<template>
  <div class="fuiler">
    <div class="nav_block">
      <Header class="Header"></Header>
      <div class="block">
        <div class="NavTitle"></div>
        报名通道
      </div>
    </div>
    <div class="main">
      <div class="black">
        <div class="block" @click="black">返回</div>
      </div>
      <div class="img"></div>
    </div>
    <Footer class="Footer"></Footer>
  </div>
</template>

<script>
import Header from "../conponents/Header";
import Footer from "../conponents/Footer";

export default {
  data() {
    return {};
  },
  mounted() {},
  components: {
    Header,
    Footer,
  },
  methods: {
    black() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped lang="scss">
.fuiler {
  width: 100%;
  height: 100%;
  position: relative;
  background-size: 100% 100%;

  .Header {
    background-image: url("../../../assets/Image/public/header.png");
    background-size: 100% 100%;
    position: initial;
    height: 4rem;
  }

  .nav_block {
    position: relative;
    background-size: 130% 130%;
    background-position: center center;
    background-image: url("../../../assets/Image/mobile/details/NavBg.png");
    width: 100%;
    height: 11rem;
    @mixin block {
      width: 100%;
      text-align: center;
      position: absolute;
      color: #ffffff;
      font-size: 1rem;
      font-style: italic;
    }

    .Time {
      @include block;
      top: 5rem;
    }

    .Title {
      @include block;
      font-size: 1.2rem;
      font-style: italic;
      top: 6.7rem;
    }
  }

  .main {
    width: 100%;
    margin-bottom: 8rem;
    .black {
      width: 100%;
      height: 2rem;
      margin-top: 0.8rem;
      margin-bottom: 0.8rem;
      position: relative;
      .browse {
        color: #78432e;
        position: absolute;
        left: 1.9rem;
        font-size: 0.8rem;
        top: 0.5rem;
        text-decoration: underline;
      }
      .block {
        position: absolute;
        right: 1.5rem;
        border-radius: 0.4rem;
        font-size: 0.8rem;
        padding: 0.5rem 1.5rem;
        background-color: #93523a;
        color: #ffffff;
      }
    }
    .img {
      width: 21rem;
      height: 40rem;
      background-image: url("../../../assets/Image/mobile/sign/bg.png");
      background-size: 100% 100%;
      margin: 0 auto;
    }
  }

  .Footer {
    background-image: url("../../../assets/Image/public/header.png");
    background-size: 100% 100%;
    position: initial;
  }
}
</style>
